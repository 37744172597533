import React , { PureComponent } from "react";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import * as myConstClass from '../../constant.js';
import $ from 'jquery'; 
import { toastr } from "react-redux-toastr";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput
} from "reactstrap";

import avatar from "../../assets/img/avatars/avatar.jpg";
const image  = `http://workdex.workzoneplus.com/backend/img/workdex-logo.png`;
const image2 = `http://workdex.workzoneplus.com/backend/img/clock.png`;
const image3 = `http://workdex.workzoneplus.com/img/logo2.png`;


// const {logout} = props.location.state;
class Reset extends PureComponent {
  constructor(props) {
    super(props);
    this.routeChange = this.routeChange.bind(this);
    this.state = {
      // user_id:'',
      newpassword: '',
      confirmPassword: '',
      showPassword: false,
      toDashboard: false,
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    };
    this.login = this.login.bind(this);
  }

  routeChange() {
  this.props.history.push('/');
  }
  onFormSubmit = e => {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };
    //new changes
    e.preventDefault();
    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    const answer_array = this.props.location.pathname.split('/');
    if(answer_array['3']){
      this.state.user_id = answer_array['3'];
      fetch(myConstClass.BASE_URL+`user/forgot/`+answer_array['3']+'/'+answer_array['4'], {
      method: 'POST',
      body: JSON.stringify($('#pform').serializeArray()),
      }).then(response =>  response.json()).then((response) => {
      if(response == 3){
        toastr.error(
            'Error',
            "Please enter New password..!",
            this.options
          )
      }
      else if(response == 4){
       toastr.error(
           'Error',
           "Please enter Confirm Password..!",
           this.options
         )
     } else if (response == 6){
       toastr.success(
           'Success',
           "Password Chnaged Successfully..!",
           this.options
      )
      this.routeChange();
     } else {
       toastr.error(
         'Error',
         "Can Not Reset Password..!",
         this.options
       )
     }
     })
     .catch(); 
    };
   }
  


  login(e) {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };
    e.preventDefault();
    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    //const { newpassword, confirmPassword } = this.state;
    // perform all neccassary validations
   var admin_id = localStorage.getItem('uname');
   //alert (admin_id);
    console.log('-->', formData);
    fetch(myConstClass.BASE_URL+`user/forgot/`+admin_id, {
    method: 'POST',
    body: JSON.stringify($('#pform').serializeArray()),
    }).then(response =>  response.json()).then((response) => {
     
       if(response == 3){
       toastr.error(
           'Error',
           "Please enter New password..!",
           this.options
         )
     }
     else if(response == 4){
      toastr.error(
          'Error',
          "Please enter  Confirm Password..!",
          this.options
        )
    }  else if(response == 5){
      toastr.error(
          'Error',
          "newpassword and ConfirmPassword not match..!",
          this.options
        )
    }else if(response == 6){
       toastr.success(
      'Success',
      "Password Successfully changes..!",
      this.options
    )
    this.props.history.push('/');
        }else if(response !== false){
      var sarray = response.split('-');
      //var admin_id= localStorage.getItem('uname'); 
    // alert (sarray);
      localStorage.setItem('uname', sarray['2']);
      localStorage.setItem('utype', sarray['1']);
      localStorage.setItem('session_id', sarray['0']);
      this.setState(() => ({
        toDashboard: true
      }))   
    } else {
     toastr.error(
       'Error',
       "Login Unsuccessful ..!",
       this.options
     )
   }
    })
    .catch();   
  }
  
  render() {
    if (this.state.toDashboard === true) {
      window.location.href = '/'; 
      return <Redirect to='/' />
    }
return(
  <React.Fragment>
    <div className="text-center mt-4">   
            <h2> Reset Password</h2>
                </div>
    <Card>
  
      <CardBody>

        <div className="m-sm-4">
            
          {/* <img src={image2} style={{'width':'40px','height':'40px'}}></img> */}
          {/* <img src={image2} style={{'width':'60px','height':'60px;' }} hspace={20}></img>
          <img src={image} style={{'width':'300px','height':'38px;'}}></img>
            */}
         
          <Form className="form" id="pform"  onSubmit={this.onFormSubmit} >
          <div className="m-sm-4">
           
            <FormGroup>
              <Label>New password </Label>
              <Input
                bsSize="lg"
                type="password"
                name="newpassword"
                placeholder="Enter your new password"
              />
              {/* <small>
                <Link to="/auth/reset-password">Forgot password?</Link>
              </small> */}
            </FormGroup>
            <FormGroup>
              <Label>Confirm Password</Label>
              <Input
                bsSize="lg"
                type="password"
                name="confirmPassword"
                placeholder="Enter your confium password"
              />
              {/* <small>
                <Link to="/auth/reset-password">Forgot password?</Link>
              </small> */}
            </FormGroup></div>
           {/* <center> <div> */}
              {/* <CustomInput
                type="checkbox"
                id="rememberMe"
                label="Remember me next time"
                defaultChecked
              /> */}
            {/* Don't have an account ? <a href="http://localhost:3000/signup" > 
           Sign Up Now</a> */}
            {/* </div></center> */}

            <div className="text-center mt-3">    
            {/* <Link to="/dashboard"> 
               <Button color="primary" size="lg"  onSubmit={this.onFormSubmit}>               
               Sign in
             </Button>  
              </Link> */}
             {/* <a href="http://localhost:3000/dashboard" > 
              <Button color="primary" size="lg"  onSubmit={this.onFormSubmit}>               
               Sign in
             </Button>        
           </a> */}
         </div> 
            <div className="text-center mt-3">
              <Link to="/dashboard">
                <Button color="primary" size="lg" onClick={this.onFormSubmit}>
                  Submit
                </Button>

              </Link>
            </div>
            {/* <center><img src={image3} style={{'width':'40%'}}></img><br/>    */}
       {/* <p><a href="https://www.epenh.com" target="_blank">Developed by Epenh Co. Ltd © 2019</a></p></center> */}
    
          </Form>
        </div>
      </CardBody>
    </Card>
    
    {/* <center><img src={image3} style={{'width':'35%'}}></img><br/>   
       <p><a href="https://www.epenh.com" target="_blank">Developed by Epenh Co. Ltd © 2019</a></p></center> */}
  </React.Fragment>
)
  }

}
export default Reset;
