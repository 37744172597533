import React,{ PureComponent } from "react";
import * as myConstClass from '../../../constant.js';
import { Line } from "react-chartjs-2";
import { connect } from "react-redux";
import './header.scss';
import {
  Card, Container,Row,Table,
  CardBody,
  CardHeader,
  CardTitle,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";

import { MoreVertical, RefreshCw } from "react-feather";

// const Ava  = `https://alpha.workzoneplus.com/img/profile.png`;
const Ava  = `http://workdex.workzoneplus.com/img/profile.png`;
class Calendar extends PureComponent {
  constructor(props) {
    super(props);
   
    this.state = {
      user_id:0,
      checkin: [],
      absent: [],
      posts: [],
      emp: [],
      formData:'',
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    }
  }


  componentDidMount() {
    var admin_id = localStorage.getItem('uname'); 
    fetch(myConstClass.BASE_URL+'attendance/getUpcomingLeaves/'+admin_id)
    .then(response => response.json())
    .then(response =>{this.setState({posts: response})}).catch(err => console.log(err))   

  }

  render() {
    const {posts} = this.state;
    const csvData = this.state.absent.rows;
    let tableData;
    if(posts == ''){
      tableData = <h5><br/>Data Not Available....!</h5>;
    } else {
     tableData =posts.map((posts) =>
     <tr>
          <td><img className="topbar__avatar-img" src={Ava} alt="avatar" /></td>
          <td>{posts.employee}</td>
          <td>{posts.from_date}</td>
          <td>{posts.to_date}</td>
          <td>{posts.leave_type}</td>
      </tr>
   );
    }
    return (

        <Container>
          <Card>
          <CardTitle tag="h3" className="mb-0">
           <h4 className="heading"> Upcoming Leave For 60 Days </h4>
          </CardTitle><br/>
          <CardBody className="py-3">
          <div class="ScrollStyle" id="vertical_scroll">
          <Table striped className="my-0">
            <thead>
                <tr>
                  <th>Image</th>
                  <th className="d-none d-xl-table-cell">Name</th>
                  <th className="d-none d-xl-table-cell">From Date</th>
                  <th className="d-none d-xl-table-cell">To Date</th>
                  <th className="d-none d-md-table-cell">Leave Type</th>
                </tr>
              </thead>
              <tbody>
                    {tableData}
                </tbody>
               </Table>
               </div>
             </CardBody>
           </Card>
         </Container>
    );
  }

}





// import React from "react";
// import {
//   Card,
//   CardBody,
//   CardHeader,
//   CardTitle,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
//   UncontrolledDropdown
// } from "reactstrap";

// import { MoreVertical, RefreshCw } from "react-feather";

// import DateTime from "react-datetime";

// const Calendar = () => (
//   <Card className="flex-fill w-100">
//     <CardHeader>
//       <div className="card-actions float-right">
//         <span className="cursor-pointer mr-1">
//           <RefreshCw />
//         </span>{" "}
//         <UncontrolledDropdown className="d-inline-block">
//           <DropdownToggle tag="a">
//             <MoreVertical />
//           </DropdownToggle>
//           <DropdownMenu right>
//             <DropdownItem>Action</DropdownItem>
//             <DropdownItem>Another Action</DropdownItem>
//             <DropdownItem>Something else here</DropdownItem>
//           </DropdownMenu>
//         </UncontrolledDropdown>
//       </div>
//       <CardTitle tag="h5" className="mb-0">
//         Calendar
//       </CardTitle>
//     </CardHeader>
//     <CardBody className="d-flex">
//       <div className="align-self-center w-100">
//         <DateTime
//           input={false}
//           defaultValue={DateTime.moment()}
//           dateFormat="L"
//           timeFormat={false}
//         />
//       </div>
//     </CardBody>
//   </Card>
// );

export default Calendar;
