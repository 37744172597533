import React , { PureComponent } from "react";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import * as myConstClass from '../../constant.js';
import $ from 'jquery'; 
import { toastr } from "react-redux-toastr";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Row,
  Input,
  CustomInput
} from "reactstrap";

import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox
} from "availity-reactstrap-validation";



import avatar from "../../assets/img/avatars/avatar.jpg";
// const image = `http://localhost/workzone/backend/img/Work-Zone-Plus-logo.png`;
// const image2 = `http://localhost/workzone/backend/img/clock.png`;
// const image3 = `http://localhost/workzone/backend/img/logo2.png`;

// const image  = `https://alpha.workzoneplus.com/backend/img/Work-Zone-Plus-logo.png`;
// const image2 = `https://alpha.workzoneplus.com/backend/img/clock.png`;
// const image3 = `https://alpha.workzoneplus.com/img/logo2.png`;


const image  = `http://workdex.workzoneplus.com/backend/img/workdex-logo.png`;
// const image2 = `http://workdex.citysmile.in/backend/img/clock.png`;
// const image3 = `http://workdex.workzoneplus.com/backend/img/zicon.png`;


// const {logout} = props.location.state;
class LogInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hrm:'',
      showPassword: false,
      toDashboard: false,
      timeOut: 5000,
      showCloseButton: true,
      progressBar: true,
      position: "top-right"
    };
    this.login = this.login.bind(this);
  }


  onFormSubmit = e => {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };
    //new changes
    e.preventDefault();
    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    console.log('-->', formData);
    
    fetch(myConstClass.BASE_URL+`login/verifyLogin/`, {
    method: 'POST',
    body: JSON.stringify($('#pform').serializeArray()),
    }).then(response =>  response.json()).then((response) => {
      
      if(response == 2){ 
        toastr.error(
           'Error',
           "Please enter email..!",
           this.options
         )
     }else if(response == 3){
       toastr.error(
           'Error',
           "Please enter password ..!",
           this.options
         )
     }
     else if(response == 4){
      toastr.error(
          'Error',
          "Login Failed ...!",
          this.options
        )
    }else if(response !== false){
      var sarray = response.split('-');  alert(sarray);
      alert( sarray['2']+'   '+sarray['3']);
      // localStorage.setItem('admin_id', sarray['3']);
      localStorage.setItem('manage_promotions', sarray['3']);
      localStorage.setItem('uname', sarray['2']);
      localStorage.setItem('utype', sarray['1']);
      localStorage.setItem('session_id', sarray['0']);
      this.setState(() => ({
        toDashboard: true
      }))   
    }
    })
    .catch(); 
  }
  componentDidMount(){
    // encodeURIComponent(myUrl)
    fetch(myConstClass.BASE_URL+'login/get_hrm/'+window.location.href)
    .then(response => response.json())
    .then(response =>{this.setState({hrm: response})}).catch(err => console.log(err))
     
  
      }
  login(e) {
    const options = {
      timeOut: parseInt(this.state.timeOut),
      showCloseButton: this.state.showCloseButton,
      progressBar: this.state.progressBar,
      position: this.state.position
    };
    e.preventDefault();
    //console.log('this.refs',this.props.history.push('/dashboard_default'));
    const formData = {};
    for (const field in this.refs) {
      formData[field] = this.refs[field].value;
    }
    console.log('-->', formData);
    fetch(myConstClass.BASE_URL+`login/verifyLogin/`, {
    method: 'POST',
    body: JSON.stringify($('#pform').serializeArray()),
    }).then(response =>  response.json()).then((response) => {
     
      if(response == 2){ 
        toastr.error(
           'Error',
           "Please enter email..!",
           this.options
         )
     }else if(response == 3){
       toastr.error(
           'Error',
           "Please enter password ..!",
           this.options
         )
     } else if(response == 4){
      toastr.error(
          'Error',
          "Incorrect Credentials ...!",
          this.options
        )
    }else if(response == 5){
      toastr.error(
          'Error',
          "Invalid Password ...!",
          this.options
        )
    } else if(response == 6){
      toastr.error(
          'Error',
          "Invalid Email ID ...!",
          this.options
        )
    }else if(response !== false){ 
      var sarray = response.split('-');
      localStorage.setItem('manage_promotions', sarray['3']);
      localStorage.setItem('uname', sarray['2']);
      localStorage.setItem('utype', sarray['1']);
      localStorage.setItem('session_id', sarray['0']);
      this.setState(() => ({
        toDashboard: true
      }))   
    } else {
     toastr.error(
       'Error',
       "Login Unsuccessful ..!",
       this.options
     )
   }
    })
    .catch();   
  }

  render() {
    if (this.state.toDashboard === true) {
      window.location.href = '/dashboard'; 
      return <Redirect to='/dashboard' />
    }
    const {hrm} = this.state;
  
return(
  <React.Fragment>
    <div className="text-center mt-4">   
    </div>
    <Card>
      <CardBody>
        <div className="m-sm-4">
          <div className="text-center">
          {/* <img src={image2} style={{'width':'40px','height':'40px'}}></img> */}
          {/* <img src={image2} style={{'width':'60px','height':'60px;' }} hspace={20}></img> */}
          <img src={image} style={{'width':'300px','height':'38px;'}}></img>
           
          </div><br/>
          <AvForm className="form" id="pform"  onSubmit={this.onFormSubmit}>
          <AvGroup>
             <Label for="example">Email</Label>
             <AvInput name="email" type="email" placeholder="Enter your email" value={hrm ? hrm.email:''}/>
         </AvGroup>
         <AvGroup>
             <Label for="example">Password</Label>
             <AvInput name="password" type="password" placeholder="Enter your password" value={hrm ? hrm.password:''}/>
         </AvGroup>
         <small>
             <Link to="/auth/reset-password">Forgot password?</Link>
        </small>
        <center> <div>
        Don't have an account ? <a href="/signup" > Sign Up Now</a>
        </div></center>
        <div className="text-center mt-3">
              <Link to="/dashboard">
                {/* <Button color="primary"   size="lg" onClick={this.login}>
                  Sign in
                </Button> */}
               <Button color="primary" type="submit" autofocus="autofocus"  onClick={this.login}>
                  Sign in
                </Button>
              </Link>
            </div>
            {/* <Row>
              <div class="col-md-2"> */}
              {/* <a target="_blank"  href="https://play.google.com/store/apps/details?id=com.easyhrm4u.freeAttendanceApp&hl=en_IN"><img src= "http://localhost/workzone/workzone/backend/uploads/play_store.png"  /></a> */}
                {/* <img src="%PUBLIC_URL%/play_store.png" />
            For Android App <a href="https://play.google.com/store/apps/details?id=com.easyhrm4u.freeAttendanceApp&hl=en_IN" target="_blank" >Click Here</a> */}
           {/* </div>
         
           <div class="col-md-2"> */}
           {/* <a target="_blank"  href="https://apps.apple.com/in/app/workzoneplus-time-attendance/id1484059797"><img src= "http://localhost/workzone/workzone/backend/uploads/app_store_new.png"  /></a> */}
            {/* For iOS App <a href="https://apps.apple.com/in/app/workzoneplus-time-attendance/id1484059797" target="_blank" >Click Here</a> */}
            {/* </div> */}
            {/* </Row> */}
      </AvForm>

          {/* <Form className="form" id="pform"  onSubmit={this.onFormSubmit} >
            <FormGroup>
              <Label>Email</Label>
              <Input
                bsSize="lg"
                type="email"
                name="email"
                placeholder="Enter your email"
                value={hrm ? hrm.email:''}
                
              />
            </FormGroup>
            <FormGroup>
              <Label>Password</Label>
              <Input
                bsSize="lg"
                type="password"
                name="password"
                placeholder="Enter your password"
                value={hrm ? hrm.password:''}
              />
               <small>
                <Link to="/auth/reset-password">Forgot password?</Link>
              </small>
            
            </FormGroup>
           <center> <div>
            Don't have an account ? <a href="/signup" > 
           Sign Up Now</a>
            </div></center>

            <div className="text-center mt-3">    
        
         </div> 
            <div className="text-center mt-3">
              <Link to="/dashboard">
            
               <Button color="primary" type="submit" autofocus="autofocus"  onClick={this.login}>
                  Sign in
                </Button>
              </Link>
            </div>
           width: 22%;
    height: 86px;
          </Form> */}
        </div>
      </CardBody>
    </Card>
    {/* <center><img src={image3} style={{'width':'19%%','height':'92px',}}></img><br/>  
       <p><a href="http://www.dexoit.com/" target="_blank">Developed by Dexoit Labs Pvt. Ltd © 2020</a></p></center> */}
  </React.Fragment>
)
  }
}

export default LogInForm;

